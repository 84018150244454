import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ProjectStore} from "./project.store";
import {environment} from "../../../environments/environment";
import {arrayRemove, arrayUpsert} from "@datorama/akita";
import {catchError, of, tap} from "rxjs";
import {ResponseState} from "../../shared/models/api/response";
import {Residence} from "../../data-access/api-residences/Residence";
import {Customer, CustomerActivity} from "../../data-access/api-customers/Customer";
import {Invoice} from "../../data-access/api-invoices/Invoice";
import {UserVacation} from "../../data-access/api-users/UserVacation";
import {User} from "../../data-access/api-users/User";
import {Country, NationalHoliday} from "../../shared/models/ui/Country";
import {Role} from "../../data-access/api-roles/Role";
import {UserPlanningEntry} from "../../data-access/api-users/UserPlanningEntry";
import {BaseData, DataLoader} from "../../shared/models/store/BaseData";
import {Folder, Request, RequestStatus} from "../../data-access/api-folders/Folder";
import {DateUtil} from "../../shared/utils/date.util";
import {LocalStorageService} from "../../shared/services/local/local-storage.service";
import {FileItem} from "../../data-access/api-files/FileItem";
import {UnitType} from "../../data-access/api-units/UnitType";
import {Unit} from "../../data-access/api-units/Unit";
import {Indices} from "../../data-access/api-caps/Indices";
import {Threshold} from "../../data-access/api-caps/Threshold";
import {TemplateEmail} from "../../data-access/api-templates/TemplateEmail";
import {Payment} from "../../data-access/api-payments/Payment";
import {Booking} from "../../data-access/api-booking/Booking";
import {Document} from "../../data-access/api-folders/Document";
import {TemplateContract} from "../../data-access/api-templates/TemplateContract";
import {CheckInOut} from "../../data-access/api-checkin-out/CheckInOut";
import {Equipment, EquipmentCategory} from "../../data-access/api-checkin-out/Equipment";
import {Room} from "../../data-access/api-checkin-out/Room";
import {TemplateCheckInOut} from "../../data-access/api-checkin-out/TemplateCheckInOut";
import {Intervention} from "../../data-access/api-intervention/Intervention";
import {Note} from "../../data-access/api-note/Note";
import {AppTask} from "../../data-access/api-task/AppTask";
import {Price} from "../../data-access/api-residences/Price";
import {v4 as uuidv4} from "uuid";




import {BOAnimation} from "../../shared/models/api/animation";
import {BORestriction} from "../../shared/models/api/restriction";
import {BOCleaning} from "../../shared/models/api/cleaning";
import {BOEquipmentMaterial} from "../../shared/models/api/equipment-material";
import {BOEquipmentCoating} from "../../shared/models/api/equipment-coating";
import {BOEquipmentStyle} from "../../shared/models/api/equipment-style";
import {BOEquipmentElement} from "../../shared/models/api/equipment-element";




export const PINNED_FOLDERS_KEY: string = 'PINNED_FOLDERS';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  private readonly baseUrl: string;
  private apiV1: string = environment.apiV1;
  private baseRef: string = environment.origin;
  private serviceCore: string = environment.service.core;
  private serviceUser: string = environment.service.user;

  constructor(
    private store: ProjectStore,
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    this.baseUrl = environment.local;
  }

  public setLoading(isLoading: boolean): void {
    this.store.setLoading(isLoading);
  }

  public init(): void {
    const today: Date = new Date();
    const leftDate: Date = DateUtil.subMonths(today, 6);
    const rightDate: Date = DateUtil.addDays(today, 1);

    // TODO: TO REMOVE IN PRODUCTION

    this.setLoading(true);

    this.getProjectNationalities();
    this.getProjectNationalHolidays();
    this.getProjectCountries();
    this.getProjectUsers();
    this.getProjectRoles();
    this.getProjectResidences();
    this.getProjectPrices();
    this.getProjectUnitTypes();
    this.getProjectUnits();
    this.getProjectUnitsNotAvailable();
    this.getProjectCustomers();
    this.getProjectRequests();
    this.getProjectFolderStatuses();
    this.getProjectRentalIndices();
    this.getProjectResourceThreshold();
    this.getProjectTemplateEmails();
    this.getProjectTemplateContracts();
    this.getProjectPayments();
    this.getProjectBookings();
    this.getProjectDocuments();
    this.getProjectCheckInOuts();
    this.getProjectEquipmentCategories();
    this.getProjectEquipments();
    this.getProjectRooms();
    this.getProjectTemplateCheckInOuts();
    this.getProjectInterventions();
    this.getProjectTasks();







  }

  private getProjectNationalities(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchNationalities()
      .pipe(
        tap((nationalities: string[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            nationalities
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  private getProjectNationalHolidays(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchNationalHolidays()
      .pipe(
        tap((nationalHolidays: any[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            nationalHolidays: this.formatNationalHolidays(nationalHolidays)
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  private getProjectCountries(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchCountries()
      .pipe(
        tap((countries: Country[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            countries
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  private getProjectUsers(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchUsers()
      .pipe(
        tap((users: User[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            users
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectRoles(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchRoles()
      .pipe(
        tap((roles: Role[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            roles
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectResidences(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchResidences()
      .pipe(
        tap((response: Residence[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            residences: response
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectPrices(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchPrices()
      .pipe(
        tap((response: Price[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            prices: response
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectUnitTypes(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchUnitTypes()
      .pipe(
        tap((response: UnitType[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            unitTypes: response
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectUnits(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchUnits()
      .pipe(
        tap((units: Unit[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            units
          }));
          console.log(units);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectUnitsNotAvailable(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchUnitsNotAvailable()
      .pipe(
        tap((response: Unit[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            unitsNotAvailable: response
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectCustomers(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchCustomers()
      .pipe(
        tap((customers: Customer[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            customers
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  public getProjectRequests(setLoading?: boolean): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchRequests()
      .pipe(
        tap((requests: Request[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            requests,
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private initPinnedFolders(folders: Folder[]): Folder[] {
    const pinnedFolders: string[] =
      this.localStorageService.getItem(PINNED_FOLDERS_KEY) || [];
    const newFolders: Folder[] = [];
    folders.forEach((folder: Folder) => {
      const newFolder: Folder = ({ ...folder });
      if (pinnedFolders.includes(newFolder.id)) {
        newFolder.pinned = true;
      } else {
        newFolder.pinned = false;
      }
      newFolders.push(newFolder);
    });
    return newFolders;
  }

  public setPinnedFolders(folder: Folder): void {
    const newFolder: Folder = ({ ...folder, pinned: folder.pinned ? !folder.pinned : true });
    let pinnedFolders: string[] = this.localStorageService.getItem(PINNED_FOLDERS_KEY) || [];
    if (pinnedFolders.includes(folder.id)) {
      pinnedFolders = pinnedFolders.filter((id: string) => id !== folder.id);
    } else {
      pinnedFolders.push(newFolder.id);
    }
    this.localStorageService.deleteItem(PINNED_FOLDERS_KEY);
    this.localStorageService.setItem(PINNED_FOLDERS_KEY, pinnedFolders);
    this.store.update((state: BaseData) => {
      const folders: Folder[] = this.initPinnedFolders(state.folders);
      return ({
        ...state,
        folders: folders,
        foldersPinned: folders.filter((folder: Folder) => folder.pinned) || []
      })
    });
  }

  private getProjectFolderStatuses(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchFolderStatuses()
      .pipe(
        tap((statuses: RequestStatus[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            requestStatuses: statuses
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectRentalIndices(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchIndices()
      .pipe(
        tap((indices: Indices[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            indices
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectResourceThreshold(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchResourceThreshold()
      .pipe(
        tap((thresholds: Threshold[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            thresholds
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectTemplateEmails(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchTemplateEmails()
      .pipe(
        tap((templateEmails: TemplateEmail[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            templateEmails
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectTemplateContracts(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchTemplateContracts()
      .pipe(
        tap((templateContracts: TemplateContract[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            templateContracts
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectPayments(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchPayments()
      .pipe(
        tap((payments: Payment[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            payments
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }

  private getProjectBookings(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchBookings()
      .pipe(
        tap((bookings: Booking[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            bookings
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  private getProjectDocuments(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchDocuments()
      .pipe(
        tap((documents: Document[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            documents
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  private getProjectCheckInOuts(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchCheckInOuts()
      .pipe(
        tap((checkInOuts: CheckInOut[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            checkInOuts
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  private getProjectEquipmentCategories(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchEquipmentCategories()
      .pipe(
        tap((equipmentCategories: EquipmentCategory[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            equipmentCategories
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  private getProjectEquipments(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchEquipments()
      .pipe(
        tap((equipments: Equipment[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            equipments
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  private getProjectRooms(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchRooms()
      .pipe(
        tap((rooms: Room[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            rooms
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  private getProjectTemplateCheckInOuts(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchTemplateCheckInOuts()
      .pipe(
        tap((templateCheckInOuts: TemplateCheckInOut[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            templateCheckInOuts
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  private getProjectInterventions(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchInterventions()
      .pipe(
        tap((interventions: Intervention[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            interventions
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      )
      .subscribe()
  }

  public getProjectTasks(): void {
    const dataLoaderId: string = uuidv4();
    this.upsertDataLoader({ id: dataLoaderId, value: true });
    this.fetchTasks()
      .pipe(
        tap((tasks: AppTask[]) => {
          this.store.update((state: BaseData) => ({
            ...state,
            tasks
          }));
          this.upsertDataLoader({ id: dataLoaderId, value: false });
        }),
        catchError((error) => {
          this.store.setError(error);
          this.upsertDataLoader({ id: dataLoaderId, value: false });
          return of(error);
        })
      ).subscribe();
  }















  // ***************************************** //
  // CRUD OPS
  // ***************************************** //

  /**
   *
   * DATA LOADER
   */

  public upsertDataLoader(dataLoader: DataLoader): void {
    this.store.update((state: BaseData) => {
      const loadingData: DataLoader[] = arrayUpsert(state.loadingData, dataLoader.id, dataLoader);
      return {
        ...state,
        loadingData
      };
    });
  }


  /**
   *
   * FOLDERS
   * REQUESTS
   */

  public upsertFolder(folder: Folder): void {
    this.store.update((state: BaseData) => {
      const folders: Folder[] = arrayUpsert(state.folders, folder.id, folder);
      return {
        ...state,
        folders
      };
    });
  }

  public upsertRequest(request: Request): void {
    this.store.update((state: BaseData) => {
      const requests: Request[] = arrayUpsert(state.requests, request.id, request);
      return {
        ...state,
        requests
      };
    });
  }

  public async deleteFolder(folderId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const folders: Folder[] = arrayRemove(state.folders, folderId);
        return {
          ...state,
          folders
        };
      });
      resolve(true);
    }));
  }

  public async deleteRequest(requestId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const requests: Request[] = arrayRemove(state.requests, requestId);
        return {
          ...state,
          requests
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * BOOKINGS
   */

  public upsertBooking(booking: Booking): void {
    this.store.update((state: BaseData) => {
      const bookings: Booking[] = arrayUpsert(state.bookings, booking.id, booking);
      return {
        ...state,
        bookings
      };
    });
  }

  public async deleteBooking(bookingId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const bookings: Booking[] = arrayRemove(state.bookings, bookingId);
        return {
          ...state,
          bookings
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * ANIMATIONS
   */

  upsertAnimation(animation: BOAnimation) {
    this.store.update((state) => {
      const animations = arrayUpsert(state.animations, animation.id, animation);
      return {
        ...state,
        animations
      };
    });
  }

  async deleteAnimation(animationId: string) {
    return new Promise<boolean>((resolve => {
      this.store.update((state) => {
        const animations = arrayRemove(state.animations, animationId);
        return {
          ...state,
          animations
        };
      });
      resolve(true);
    }));
  }

  deleteAnimationImageByImageUrl(imageUrl: string) {
    this.store.update((state) => {
      const animationId = state.animations
        .find((a) => a.animationImageUrls
          .some((img) => img === imageUrl)
        )?.id;
      const animation = state.animations
        .find((a) => a.id === animationId);
      if (animationId && animation) {
        const newImageList = [ ...animation.animationImageUrls.filter((img) => img !== imageUrl) ];
        animation.animationImageUrls = [ ...newImageList ];
        const animations = arrayUpsert(state.animations, animation.id, animation);
        return {
          ...state,
          animations
        };
      }
      return {
        ...state
      };
    });
  }

  /**
   *
   * UNITS
   */

  public upsertUnit(unit: Unit): void {
    this.store.update((state: BaseData) => {
      const units: Unit[] = arrayUpsert(state.units, unit.id, unit);
      return {
        ...state,
        units
      };
    });
  }

  public upsertUnitImages(unitImages: FileItem[], unitId: string): void {
    this.store.update((state: BaseData) => {
      const unit: Unit | undefined
        = state.units.find((r: Unit) => r.id === unitId);
      if (unit) {
        const newUnit: Unit = { ...unit };
        const newImages: FileItem[] = [ ...unitImages ];
        newUnit.unitImages = [ ...newImages ];
        const units: Unit[] = arrayUpsert(state.units, newUnit.id, newUnit);
        return {
          ...state,
          units
        };
      }
      return {
        ...state
      };
    });
  }

  public async deleteUnit(unitId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const units: Unit[] = arrayRemove(state.units, unitId);
        return {
          ...state,
          units
        };
      });
      resolve(true);
    }));
  }

  public async deleteUnitImage(unitId: string, imageId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const unit: Unit | undefined
          = state.units.find((r: Unit) => r.id === unitId);
        if (unit) {
          const images: FileItem[] = [ ...unit.unitImages ];
          const index: number = images.findIndex((image: FileItem) => image.id === imageId);
          if (index > -1) {
            unit.unitImages = [...images].splice(index, 1);
          }
          const units: Unit[] = arrayUpsert(state.units, unit.id, unit);
          return {
            ...state,
            units
          };
        }
        return {
          ...state
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * NOTES
   */

  public upsertNote(note: Note): void {
    this.store.update((state: BaseData) => {
      const notes: Note[] = arrayUpsert(state.notes, note.id, note);
      return {
        ...state,
        notes
      };
    });
  }

  public async deleteNote(noteId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const notes: Note[] = arrayRemove(state.notes, noteId);
        return {
          ...state,
          notes
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * TASKS
   */

  public upsertTask(task: AppTask): void {
    this.store.update((state: BaseData) => {
      const tasks: AppTask[] = arrayUpsert(state.tasks, task.id, task);
      return {
        ...state,
        tasks
      };
    });
  }

  public async deleteTask(taskId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const tasks: AppTask[] = arrayRemove(state.tasks, taskId);
        return {
          ...state,
          tasks
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * UNIT TYPES
   */

  public upsertUnitType(unitType: UnitType): void {
    this.store.update((state: BaseData) => {
      const unitTypes: UnitType[] = arrayUpsert(state.unitTypes, unitType.id, unitType);
      return {
        ...state,
        unitTypes
      };
    });
  }

  public upsertUnitTypeImages(unitTypeImages: FileItem[], unitTypeId: string): void {
    this.store.update((state: BaseData) => {
      const unitType: UnitType | undefined
        = state.unitTypes.find((r: UnitType) => r.id === unitTypeId);
      if (unitType) {
        const newUnitType: UnitType = { ...unitType };
        const newImages: FileItem[] = [ ...unitTypeImages ];
        newUnitType.unitTypeImages = [ ...newImages ];
        const unitTypes: UnitType[] = arrayUpsert(state.unitTypes, newUnitType.id, newUnitType);
        return {
          ...state,
          unitTypes
        };
      }
      return {
        ...state
      };
    });
  }

  public async deleteUnitType(unitTypeId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const unitTypes: UnitType[] = arrayRemove(state.unitTypes, unitTypeId);
        return {
          ...state,
          unitTypes
        };
      });
      resolve(true);
    }));
  }

  public async deleteUnitTypeImage(unitTypeId: string, imageId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const unitType: UnitType | undefined
          = state.unitTypes.find((r: UnitType) => r.id === unitTypeId);
        if (unitType) {
          const images: FileItem[] = [ ...unitType.unitTypeImages ];
          const index: number = images.findIndex((image: FileItem) => image.id === imageId);
          if (index > -1) {
            unitType.unitTypeImages = [...images].splice(index, 1);
          }
          const unitTypes: UnitType[] = arrayUpsert(state.unitTypes, unitType.id, unitType);
          return {
            ...state,
            unitTypes
          };
        }
        return {
          ...state
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * RESTRICTIONS
   */

  upsertRestriction(restriction: BORestriction) {
    this.store.update((state) => {
      const restrictions = arrayUpsert(state.restrictions, restriction.id, restriction);
      return {
        ...state,
        restrictions
      };
    });
  }

  async deleteRestriction(restrictionId: string) {
    return new Promise<boolean>((resolve => {
      this.store.update((state) => {
        const restrictions = arrayRemove(state.restrictions, restrictionId);
        return {
          ...state,
          restrictions
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * PRICES
   */

  public upsertPrice(price: Price): void {
    this.store.update((state: BaseData) => {
      const prices: Price[] = arrayUpsert(state.prices, price.id, price);
      return {
        ...state,
        prices
      };
    });
  }

  public upsertPriceImages(priceImages: FileItem[], priceId: string): void {
    this.store.update((state: BaseData) => {
      const price: Price | undefined
        = state.prices.find((r: Price) => r.id === priceId);
      if (price) {
        const newPrice: Price = { ...price };
        const newImages: FileItem[] = [ ...priceImages ];
        newPrice.priceImages = [ ...newImages ];
        const prices: Price[] = arrayUpsert(state.prices, newPrice.id, newPrice);
        return {
          ...state,
          prices
        };
      }
      return {
        ...state
      };
    });
  }

  public async deletePrice(priceId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const prices: Price[] = arrayRemove(state.prices, priceId);
        return {
          ...state,
          prices
        };
      });
      resolve(true);
    }));
  }

  public async deletePriceImage(priceId: string, imageId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const price: Price | undefined
          = state.prices.find((r: Price) => r.id === priceId);
        if (price) {
          const newPrice: Price = { ...price };
          const images: FileItem[] = [ ...newPrice.priceImages ]
            .filter((image: FileItem) => image.id === imageId);
          newPrice.priceImages = [...images];
          const prices: Price[] = arrayUpsert(state.prices, newPrice.id, newPrice);
          return {
            ...state,
            prices
          };
        }
        return {
          ...state
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * RESIDENCES
   */

  public upsertResidence(residence: Residence): void {
    this.store.update((state: BaseData) => {
      const residences: Residence[] = arrayUpsert(state.residences, residence.id, residence);
      return {
        ...state,
        residences
      };
    });
  }

  public upsertResidenceImages(residenceImages: FileItem[], residenceId: string): void {
    this.store.update((state: BaseData) => {
      const residence: Residence | undefined
        = state.residences.find((r: Residence) => r.id === residenceId);
      if (residence) {
        const newResidence: Residence = { ...residence };
        const newImages: FileItem[] = [ ...residenceImages ];
        newResidence.residenceImages = [ ...newImages ];
        const residences: Residence[] = arrayUpsert(state.residences, newResidence.id, newResidence);
        return {
          ...state,
          residences
        };
      }
      return {
        ...state
      };
    });
  }

  public async deleteResidence(residenceId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const residences: Residence[] = arrayRemove(state.residences, residenceId);
        return {
          ...state,
          residences
        };
      });
      resolve(true);
    }));
  }

  public async deleteResidenceImage(residenceId: string, imageId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const residence: Residence | undefined
          = state.residences.find((r: Residence) => r.id === residenceId);
        if (residence) {
          const newResidence: Residence = { ...residence };
          const images: FileItem[] = [ ...newResidence.residenceImages ]
            .filter((image: FileItem) => image.id === imageId);
          newResidence.residenceImages = [...images];
          const residences: Residence[] = arrayUpsert(state.residences, newResidence.id, newResidence);
          return {
            ...state,
            residences
          };
        }
        return {
          ...state
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * TEMPLATES
   */

  public upsertTemplateEmail(template: TemplateEmail): void {
    this.store.update((state: BaseData) => {
      const templates: TemplateEmail[] = arrayUpsert(state.templateEmails, template.id, template);
      return {
        ...state,
        templateEmails: templates
      };
    });
  }

  public async deleteTemplateEmail(templateId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const templates: TemplateEmail[] = arrayRemove(state.templateEmails, templateId);
        return {
          ...state,
          templateEmails: templates
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * TEMPLATES CONTRACTS
   */

  public upsertTemplateContract(template: TemplateContract): void {
    this.store.update((state: BaseData) => {
      const templates: TemplateContract[] = arrayUpsert(state.templateContracts, template.id, template);
      return {
        ...state,
        templateContracts: templates
      };
    });
  }

  public async deleteTemplateContract(templateId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const templates: TemplateContract[] = arrayRemove(state.templateContracts, templateId);
        return {
          ...state,
          templateContracts: templates
        };
      });
      resolve(true);
    }));
  }

  /**
   * INTERVENTIONS
   *
   */

  public upsertIntervention(intervention: Intervention): void {
    this.store.update((state: BaseData) => {
      const interventions: Intervention[] = arrayUpsert(state.interventions, intervention.id, intervention);
      return {
        ...state,
        interventions
      };
    });
  }

  public async deleteIntervention(interventionId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const interventions: Intervention[] = arrayRemove(state.interventions, interventionId);
        return {
          ...state,
          interventions
        };
      });
      resolve(true);
    }));
  }

  /**
   * CLEANING
   *
   */

  upsertCleaning(cleaning: BOCleaning) {
    this.store.update((state) => {
      const cleanings = arrayUpsert(state.cleanings, cleaning.id, cleaning);
      return {
        ...state,
        cleanings
      };
    });
  }

  async deleteCleaning(cleaningId: string) {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const cleanings = arrayRemove(state.cleanings, cleaningId);
        return {
          ...state,
          cleanings
        };
      });
      resolve(true);
    }));
  }

  /**
   * ROLE
   *
   */

  public upsertRole(role: Role): void {
    this.store.update((state: BaseData) => {
      const roles = arrayUpsert(state.roles, role.id, role);
      return {
        ...state,
        roles
      };
    });
  }

  public deleteRole(roleId: string): void {
    this.store.update((state: BaseData) => {
      const roles = arrayRemove(state.roles, roleId);
      return {
        ...state,
        roles
      };
    });
  }

  /**
   * User
   *
   */

  public upsertUser(user: User): void {
    const userId: string = user.id || ''; // TODO....
    this.store.update((state: BaseData) => {
      const users: User[] = arrayUpsert(state.users, userId, user);
      return {
        ...state,
        users
      };
    });
  }

  public deleteUser(userId: string): void {
    this.store.update((state) => {
      const users: User[] = arrayRemove(state.users, userId);
      return {
        ...state,
        users
      };
    });
  }

  public upsertUserAvailability(newUserPlanningEntry: UserPlanningEntry, userId: string): void {
    const user: User | undefined = this.store.getValue().users
      .find((user: User) => user.id === userId);
    if (user) {
      const newUser: any = { ...user };
      const userPlanningEntries: UserPlanningEntry[] = user.userPlanningEntries
        ? [ ...user.userPlanningEntries ]
        : [];
      const index: number = userPlanningEntries
        .findIndex((entry: UserPlanningEntry) => entry.id === newUserPlanningEntry.id);
      if (index > -1) {
        userPlanningEntries[index] = { ...newUserPlanningEntry };
      }
      newUser.userPlanningEntries = [ ...userPlanningEntries ];
      this.upsertUser(newUser);
    }
  }

  public deleteUserAvailability(entryId: string, userId: string): void {
    const user: User | undefined = this.store.getValue().users
      .find((user: User) => user.id === userId);
    if (user) {
      const newUser: any = { ...user };
      const userPlanningEntries: UserPlanningEntry[] = user.userPlanningEntries
        ? [ ...user.userPlanningEntries ]
        : [];
      const index: number = userPlanningEntries
        .findIndex((entry: UserPlanningEntry) => entry.id === entryId);
      userPlanningEntries.splice(index, 1);
      newUser.userPlanningEntries = [ ...userPlanningEntries ];
      this.upsertUser(newUser)
    }
  }

  public upsertUserVacation(newUserVacation: UserVacation, userId: string): void {
    const user: User | undefined = this.store.getValue().users
      .find((user: User) => user.id === userId);
    if (user) {
      const newUser: any = { ...user };
      const userVacations: UserVacation[] = user.userVacations
        ? [ ...user.userVacations ]
        : [];
      const index: number = userVacations
        .findIndex((vacation: UserVacation) => vacation.id === newUserVacation.id);
      if (index > -1) {
        userVacations[index] = { ...newUserVacation };
      }
      newUser.userVacations = [ ...userVacations ];
      this.upsertUser(newUser);
    }
  }

  public deleteUserVacation(vacationId: string, userId: string): void {
    const user: User | undefined = this.store.getValue().users
      .find((user: User) => user.id === userId);
    if (user) {
      const newUser: any = { ...user };
      const userVacations: UserVacation[] = user.userVacations
        ? [ ...user.userVacations ]
        : [];
      const index: number = userVacations
        .findIndex((vacation: UserVacation) => vacation.id === vacationId);
      userVacations.splice(index, 1);
      newUser.userVacations = [ ...userVacations ];
      this.upsertUser(newUser);
    }
  }

  /**
   * Rental References Indices
   *
   */

  public upsertIndices(index: Indices): void {
    this.store.update((state: BaseData) => {
      const indices: Indices[] = arrayUpsert(state.indices, index.id, index);
      return {
        ...state,
        indices
      };
    });
  }

  public async deleteIndices(indicesId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const indices: Indices[] = arrayRemove(state.indices, indicesId);
        return {
          ...state,
          indices
        };
      });
      resolve(true);
    }));
  }

  /**
   * Applicant Resource Thresholds
   *
   */

  public upsertThreshold(threshold: Threshold): void {
    this.store.update((state: BaseData) => {
      const thresholds: Threshold[] = arrayUpsert(state.thresholds, threshold.id, threshold);
      return {
        ...state,
        thresholds
      };
    });
  }

  public async deleteThreshold(thresholdId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const thresholds: Threshold[] = arrayRemove(state.thresholds, thresholdId);
        return {
          ...state,
          thresholds
        };
      });
      resolve(true);
    }));
  }

  /**
   * Room State Equipment Category
   *
   */

  public upsertEquipmentCategory(equipmentCategory: EquipmentCategory): void {
    this.store.update((state: BaseData) => {
      const equipmentCategories: EquipmentCategory[] = arrayUpsert(state.equipmentCategories, equipmentCategory.id, equipmentCategory);
      return {
        ...state,
        equipmentCategories
      };
    });
  }

  public async deleteEquipmentCategory(equipmentCategoryId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const equipmentCategories: EquipmentCategory[] = arrayRemove(state.equipmentCategories, equipmentCategoryId);
        return {
          ...state,
          equipmentCategories
        };
      });
      resolve(true);
    }));
  }

  /**
   * Template Check In / Check Out (Inventory of Fixtures)
   *
   */

  public upsertTemplateCheckInOut(templateCheckInOut: TemplateCheckInOut): void {
    this.store.update((state: BaseData) => {
      const templateCheckInOuts: TemplateCheckInOut[] = arrayUpsert(state.templateCheckInOuts, templateCheckInOut.id, templateCheckInOut);
      return {
        ...state,
        templateCheckInOuts
      };
    });
  }

  public async deleteTemplateCheckInOut(templateId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const templateCheckInOuts: TemplateCheckInOut[] = arrayRemove(state.templateCheckInOuts, templateId);
        return {
          ...state,
          templateCheckInOuts
        };
      });
      resolve(true);
    }));
  }

  /**
   * Equipments
   *
   */

  public upsertEquipment(equipment: Equipment): void {
    this.store.update((state: BaseData) => {
      const equipments: Equipment[] = arrayUpsert(state.equipments, equipment.id, equipment);
      return {
        ...state,
        equipments
      };
    });
  }

  public async deleteEquipment(equipmentId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const equipments: Equipment[] = arrayRemove(state.equipments, equipmentId);
        return {
          ...state,
          equipments
        };
      });
      resolve(true);
    }));
  }

  /**
   * Room State Room
   *
   */

  public upsertRoom(room: Room): void {
    this.store.update((state: BaseData) => {
      const rooms: Room[] = arrayUpsert(state.rooms, room.id, room);
      return {
        ...state,
        rooms
      };
    });
  }

  public async deleteRoom(roomId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const rooms: Room[] = arrayRemove(state.rooms, roomId);
        return {
          ...state,
          rooms
        };
      });
      resolve(true);
    }));
  }

  /**
   * Equipment Material
   *
   */

  async upsertMaterial(material: BOEquipmentMaterial) {
    return new Promise<boolean>((resolve => {
      this.store.update((state) => {
        const equipmentMaterials = arrayUpsert(state.equipmentMaterials, material.id, material);
        return {
          ...state,
          equipmentMaterials
        };
      });
      resolve(true);
    }));
  }

  async deleteMaterial(materialId: string) {
    return new Promise<boolean>((resolve => {
      this.store.update((state) => {
        const equipmentMaterials = arrayRemove(state.equipmentMaterials, materialId);
        return {
          ...state,
          equipmentMaterials
        };
      });
      resolve(true);
    }));
  }

  /**
   * Equipment Coating
   *
   */

  async upsertCoating(coating: BOEquipmentCoating) {
    return new Promise<boolean>((resolve => {
      this.store.update((state) => {
        const equipmentCoatings = arrayUpsert(state.equipmentCoatings, coating.id, coating);
        return {
          ...state,
          equipmentCoatings
        };
      });
      resolve(true);
    }));
  }

  async deleteCoating(coatingId: string) {
    return new Promise<boolean>((resolve => {
      this.store.update((state) => {
        const equipmentCoatings = arrayRemove(state.equipmentCoatings, coatingId);
        return {
          ...state,
          equipmentCoatings
        };
      });
      resolve(true);
    }));
  }

  /**
   * Equipment Style
   *
   */

  async upsertStyle(style: BOEquipmentStyle) {
    return new Promise<boolean>((resolve => {
      this.store.update((state) => {
        const equipmentStyles = arrayUpsert(state.equipmentStyles, style.id, style);
        return {
          ...state,
          equipmentStyles
        };
      });
      resolve(true);
    }));
  }

  async deleteStyle(styleId: string) {
    return new Promise<boolean>((resolve => {
      this.store.update((state) => {
        const equipmentStyles = arrayRemove(state.equipmentStyles, styleId);
        return {
          ...state,
          equipmentStyles
        };
      });
      resolve(true);
    }));
  }

  /**
   * Equipment Element
   *
   */

  async upsertElement(element: BOEquipmentElement) {
    return new Promise<boolean>((resolve => {
      this.store.update((state) => {
        const equipmentElements = arrayUpsert(state.equipmentElements, element.id, element);
        return {
          ...state,
          equipmentElements
        };
      });
      resolve(true);
    }));
  }

  async deleteElement(elementId: string) {
    return new Promise<boolean>((resolve => {
      this.store.update((state) => {
        const equipmentElements = arrayRemove(state.equipmentElements, elementId);
        return {
          ...state,
          equipmentElements
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * CHECK IN OUT / ROOM STATES
   */

  public upsertCheckInOut(checkInOut: CheckInOut): void {
    this.store.update((state: BaseData) => {
      const checkInOuts: CheckInOut[] = arrayUpsert(state.checkInOuts, checkInOut.id, checkInOut);
      return {
        ...state,
        checkInOuts
      };
    });
  }

  public async deleteCheckInOut(checkInOutId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const checkInOuts: CheckInOut[] = arrayRemove(state.checkInOuts, checkInOutId);
        return {
          ...state,
          checkInOuts
        };
      });
      resolve(true);
    }));
  }

  /**
   *
   * CUSTOMERS
   */

  public upsertCustomer(customer: Customer): void {
    this.store.update((state: BaseData) => {
      const customers: Customer[] = arrayUpsert(state.customers, customer.id, customer);
      return {
        ...state,
        customers
      };
    });
  }

  public async deleteCustomer(customerId: string): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.store.update((state: BaseData) => {
        const customers: Customer[] = arrayRemove(state.customers, customerId);
        return {
          ...state,
          customers
        };
      });
      resolve(true);
    }));
  }

  public upsertCustomerActivity(customerId: string, customerActivity: CustomerActivity, activityId?: string): void {
    const customer: Customer | undefined = this.store.getValue().customers
      .find((cus: Customer) => cus.id === customerId);

    if (customer) {
      const newCustomer: Customer = { ...customer };
      const newCustomerActivities: CustomerActivity[] = customer.customerActivities && customer.customerActivities.length
        ? [ ...customer.customerActivities ]
        : [];

      if (activityId) {
        const index: number = newCustomerActivities
          .findIndex((activity: CustomerActivity) => activity.id === customerActivity.id);
        if (index > -1) {
          newCustomerActivities[index] = { ...customerActivity };
        }
      } else {
        newCustomerActivities.push(customerActivity);
      }
      newCustomer.customerActivities = [ ...newCustomerActivities ];
      this.upsertCustomer(newCustomer);
    }
  }

  public deleteCustomerActivity(customerId: string, activityId: string): void {
    const customer: Customer | undefined = this.store.getValue().customers
      .find((cus: Customer) => cus.id === customerId);

    if (customer) {
      const newCustomer: Customer = { ...customer };
      const newCustomerActivities: CustomerActivity[] = customer.customerActivities && customer.customerActivities.length
        ? [ ...customer.customerActivities]
        : [];
      const index: number = newCustomerActivities
        .findIndex((activity: CustomerActivity) => activity.id === activityId);
      newCustomerActivities.splice(index, 1);
      newCustomer.customerActivities = [ ...newCustomerActivities ];
      this.upsertCustomer(newCustomer);
    }
  }

  /**
   *
   * INVOICES
   */

  public upsertInvoice(invoice: Invoice): void {
    this.store.update((state: BaseData) => {
      const invoices: Invoice[] = arrayUpsert(state.invoices, invoice.id, invoice);
      return {
        ...state,
        invoices
      };
    });
  }

  /**
   *
   * PAYMENTS
   */

  public upsertPayment(payment: Payment): void {
    this.store.update((state: BaseData) => {
      const payments: Payment[] = arrayUpsert(state.payments, payment.id, payment);
      return {
        ...state,
        payments
      };
    });
  }

  // ***************************************** //
  // HTTP OPS
  // ***************************************** //

  private fetchCountries = () => this.httpClient.get<Country[]>(
    `${this.baseUrl}/countries.json`
  );

  private fetchNationalities = () => this.httpClient.get<string[]>(
    `${this.baseUrl}/nationalities.json`
  );

  private fetchNationalHolidays = () => this.httpClient.get<any[]>(
    `https://calendrier.api.gouv.fr/jours-feries/alsace-moselle/${new Date().getFullYear().toString()}.json`
  );

  private formatNationalHolidays(nationalHolidays: any): NationalHoliday[] {
    return Object.keys(nationalHolidays).map((key: string) => ({
      date: new Date(key).getTime(),
      description: `${nationalHolidays[key]}`
    }));
  }

  private fetchUsers = () => this.httpClient.get<User[]>(
    `${this.baseRef}${this.serviceUser}${this.apiV1}users`
  );

  private fetchRoles = () => this.httpClient.get<Role[]>(
    `${this.baseRef}${this.serviceUser}${this.apiV1}roles`
  );

  private fetchVacations = () => this.httpClient.get<UserVacation[]>(
    `${this.baseRef}${this.serviceUser}${this.apiV1}users/vacations`
  );

  private fetchCustomers = () => this.httpClient.get<Customer[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}customers`
  );

  private fetchInvoices = () => this.httpClient.get<Invoice[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}invoices`
  );

  private fetchRequests = () => this.httpClient.get<Request[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}requests`
  );

  private fetchFolderStatuses = () => this.httpClient.get<RequestStatus[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}folder/statuses`
  );

  private fetchResidences = () => this.httpClient.get<Residence[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}residences`
  );

  private fetchPrices = () => this.httpClient.get<Price[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}residences/services`
  );

  private fetchUnitTypes = () => this.httpClient.get<UnitType[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}unit-types`
  );

  private fetchUnits = () => this.httpClient.get<Unit[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}units`
  );

  private fetchUnitsNotAvailable = () => this.httpClient.get<Unit[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}units/unavailabilities`
  );

  private fetchIndices = () => this.httpClient.get<Indices[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}housings/indices`
  );

  private fetchResourceThreshold = () => this.httpClient.get<Threshold[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}housings/caps`
  );

  private fetchTemplateEmails = () => this.httpClient.get<TemplateEmail[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}templates/mails`
  );

  private fetchTemplateContracts = () => this.httpClient.get<TemplateContract[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}templates/contracts`
  );

  private fetchPayments = () => this.httpClient.get<Payment[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}payments`
  );

  private fetchBookings = () => this.httpClient.get<Booking[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}bookings`
  );

  private fetchDocuments = () => this.httpClient.get<Document[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}documents`
  );

  private fetchCheckInOuts = () => this.httpClient.get<CheckInOut[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}checkin-out`
  );

  private fetchEquipmentCategories = () => this.httpClient.get<EquipmentCategory[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}equipments/categories`
  );

  private fetchEquipments = () => this.httpClient.get<Equipment[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}equipments`
  );

  private fetchRooms = () => this.httpClient.get<Room[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}rooms`
  );

  private fetchTemplateCheckInOuts = () => this.httpClient.get<TemplateCheckInOut[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}templates/inventories`
  );

  private fetchInterventions = () => this.httpClient.get<Intervention[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}interventions`
  );

  private fetchTasks = () => this.httpClient.get<AppTask[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}tasks`
  );





















  private fetchAnimations = () => this.httpClient.get<ResponseState<BOAnimation[]>>(
    `${this.baseUrl}/animations.json`
  );

  /**
   * Admins
   */

  private fetchRestrictions = () => this.httpClient.get<ResponseState<BORestriction[]>>(
    `${this.baseUrl}/restrictions.json`
  );

  private fetchCleanings = () => this.httpClient.get<ResponseState<BOCleaning[]>>(
    `${this.baseUrl}/cleanings.json`
  );

  private fetchEquipmentMaterials = () => this.httpClient.get<ResponseState<BOEquipmentMaterial[]>>(
    `${this.baseUrl}/equipmentMaterials.json`
  );

  private fetchEquipmentCoatings = () => this.httpClient.get<ResponseState<BOEquipmentCoating[]>>(
    `${this.baseUrl}/equipmentCoatings.json`
  );

  private fetchEquipmentStyles = () => this.httpClient.get<ResponseState<BOEquipmentStyle[]>>(
    `${this.baseUrl}/equipmentStyles.json`
  );

  private fetchEquipmentElements = () => this.httpClient.get<ResponseState<BOEquipmentElement[]>>(
    `${this.baseUrl}/equipmentElements.json`
  );

}
