import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {BaseData} from "../../shared/models/store/BaseData";

export type ProjectState = BaseData;

function createInitialState(): ProjectState {
  return {
    loadingData: [],
    folders: [],
    requests: [],
    foldersPinned: [],
    requestStatuses: [],
    nationalities: [],
    nationalHolidays: [],
    customers: [],
    countries: [],
    users: [],
    roles: [],
    vacations: [],
    residences: [],
    prices: [],
    unitTypes: [],
    units: [],
    unitsNotAvailable: [],
    indices: [],
    thresholds: [],
    templateEmails: [],
    templateContracts: [],
    payments: [],
    bookings: [],
    documents: [],
    checkInOuts: [],
    equipmentCategories: [],
    equipments: [],
    rooms: [],
    templateCheckInOuts: [],
    interventions: [],
    tasks: [],
    notes: [],


    animations: [],
    invoices: [],
    restrictions: [],
    cleanings: [],
    equipmentMaterials: [],
    equipmentCoatings: [],
    equipmentStyles: [],
    equipmentElements: [],
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({
  name: 'project'
})
export class ProjectStore extends Store<ProjectState> {
  constructor() {
    super(createInitialState());
  }
}
